<template>
    <div class="login-wrap">
        <transition name="el-fade-in-linear">
            <div class="ms-login" v-show="show">
                <div class="ms-title">哈工精密管理系统</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ms-content">
                    <el-form-item prop="user">
                        <el-input v-model.trim="ruleForm.user" placeholder="username">
                            <template #prepend>
                                <el-button icon="el-icon-user"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="pass">
                        <el-input type="password" placeholder="password" v-model="ruleForm.pass"
                                  @keyup.enter.native="submitForm('ruleForm')">
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div class="login-btn">
                        <el-button type="primary" @click="submitForm('ruleForm')"
                                   v-loading.fullscreen.lock="fullscreenLoading">登录
                        </el-button>
                    </div>
                    <p class="login-tips">
                        <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021178225号</a>
                    </p>
                </el-form>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            let validateUser = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入用户名'));
                } else {
                    callback();
                }
            };
            let validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    callback();
                }
            };
            return {
                show:false,
                fullscreenLoading: false,//loading框
                ruleForm: {
                    user: '',
                    pass: '',
                },
                rules: {
                    pass: [
                        {validator: validatePass, trigger: 'blur'}
                    ],
                    user: [
                        {validator: validateUser, trigger: 'blur'}
                    ]
                }
            };
        },
        mounted(){
            setTimeout(()=>{
                this.show = true;
            },300);
        },
        methods: {
            //点击登录
            async submitForm(formName) {
                this.$refs[formName].validate(async valid => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        try {
                            const {data} = await this.$axios({
                                method: 'POST',
                                url: '/api/account/login',
                                data: {
                                    username: this.ruleForm.user,
                                    passwword: this.ruleForm.pass,
                                }
                            });
                            this.fullscreenLoading = false;
                            if (data.code) {
                                this.$store.dispatch('login', data.data);
                                this.$notify({
                                    title: data.msg,
                                    type: 'success',
                                    duration: 500
                                });
                            } else {
                                this.$notify.error({
                                    title: data.msg,
                                });
                            }
                        } catch (e) {
                            this.fullscreenLoading = false;
                            this.$message.error('网络错误，请稍后重试');
                        }
                    } else {
                        this.$message.error("请输入账号和密码");
                        return false;
                    }
                });
            }
        }
    };
</script>

<style scoped>
    .login-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../assets/imgs/login-bg.jpg);
        background-size: 100%;
    }

    .ms-login {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 350px;
        margin: -190px 0 0 -175px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
        /*box-shadow: 0 0 15px 0 #ffffff;*/
    }

    .ms-title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #fff;
        border-bottom: 1px solid #ddd;
    }

    .ms-content {
        padding: 30px 30px;
    }

    .login-btn {
        text-align: center;
    }

    .login-btn button {
        width: 100%;
        height: 36px;
        margin-bottom: 10px;
    }

    .login-tips{
        text-align: center;
    }
    .login-tips  a{
        font-size: 12px;
        line-height: 30px;
        color: #fff;
    }
</style>